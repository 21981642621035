<template>
  <div>
    <Modal
      v-show="isModalVisible"
      :constat="flux.constat"
      :type="flux.level2"
      @close="closeModal"
      @edit="editRepair"
      @evenement="evenement"
    />
    <v-row>
      <v-col cols="12">
        <h2 class="mt10 pt10">ATTACHE / RÉGLEUR / BOUTON</h2>
        <div class="spacer"></div>
        <div>
          <b>Choisissez le type de réparation que vous souhaitez</b>
        </div>
        <div class="spacer"></div>
        <v-tabs
          v-model="active_tab"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          @change="onChangeTab"
        >
          <v-tab href="#agraphe-dos">
            AGRAFE DOS
          </v-tab>
          <v-tab href="#regleur">
            RÉGLEUR
          </v-tab>
          <v-tab href="#attache-maillot-bain">
            ATTACHE MAILLOT DE BAIN
          </v-tab>
          <v-tab href="#bouton">
            BOUTON
          </v-tab>

          <v-tab-item id="agraphe-dos">
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="regleur">
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="attache-maillot-bain">
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
          <v-tab-item id="bouton">
            <KeepAlive>
              <component v-bind:is="flux.activeTab" @evenement="evenement" @showmodal="showModal"></component>
            </KeepAlive>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex'
import Modal from '../Modal.vue';


export default {
  name: 'AttacheRegleurBouton',
  data() {
    return {
      tabName: null,
      isModalVisible: false
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
    active_tab: {
      get() {
        return this.flux.activeTab
      },
      set(newValue) {
        this.setActiveTab(newValue)
      }
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setActiveTab']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    onChangeTab(value) {
      this.tabName = value
      this.setActiveTab(value)
    },
    editRepair(indexes) {
      this.$emit('edit', indexes)
    },
    evenement($value) {
      this.$emit('evenement', $value)
    },
    showModal() {
      this.$emit('showmodal', this.flux.level2)
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  components: {
    Modal,
    'agraphe-dos': () => import('./agraphe-dos.vue'),
    regleur: () => import('./regleur.vue'),
    'attache-maillot-bain': () => import('./attache-maillot-bain.vue'),
    bouton: () => import('./bouton.vue'),
  }
}
</script>
